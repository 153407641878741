
.ActivityLogDataTable
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 6px !important
}
.ActivityLogDataTable
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 6px !important;
    /* white-space:normal !important */
}

.ActivityLogDataTable
  .p-datatable-table
  .p-datatable-tfoot
  td {
    padding: 6px !important
}
.ActivityLogDataTable
  .index-column {
    width: 25px !important
}
.ActivityLogDataTable
  .p-paginator {
    font-size: small !important;
}
.ActivityLogDataTable
  .p-datatable-table
  .p-datatable-thead
  {
    z-index: auto !important;
}