
.StatementOfDutiesPaidOrPayable
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 2px !important
}
.StatementOfDutiesPaidOrPayable
  .p-datatable-table
  .p-datatable-tbody
  td:first-child {
    padding: 10px !important
}
.StatementOfDutiesPaidOrPayable
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 5px !important;
    white-space:normal !important
}
.StatementOfDutiesPaidOrPayable
  .p-datatable-table
  .p-datatable-thead
  th:first-child {
    padding: 10px !important;
}

.error-input {
  border: 2px solid red !important;
  border-radius: 6px !important; 
  width: -webkit-fill-available;
}

/* background of selected row */
.StatementOfDutiesPaidOrPayable .p-datatable .p-datatable-tbody > tr.p-highlight{
  background-color: #0057db3b !important;
}

/* selectd row checkbox color */
.StatementOfDutiesPaidOrPayable .p-checkbox.p-highlight .p-checkbox-box{
  border-color: #0057db !important;
  background-color: #0057db !important;
}
.StatementOfDutiesPaidOrPayable .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box{
  border-color: #0057db !important;
}

/* dropdown colors */
/* .input-dropdown-fields {
  width: -webkit-fill-available;
}
.input-dropdown-fields:hover{
  border-color: #0057db !important;
}
.input-dropdown-fields:focus {
  border-color: #0057db !important;
  box-shadow: 0 0 4px 0 #0057db !important;
}
.StatementOfDutiesPaidOrPayable .p-dropdown .p-focus {
  border-color: #0057db !important;
  box-shadow: 0 0 4px 0 #0057db !important;
}
.StatementOfDutiesPaidOrPayable .p-dropdown:not(.p-disabled).p-focus{
  box-shadow: 0 0 4px 0 #0057db !important;
} */

/* number field selection and focus color */
.numberField {
  /* min-width: 100% !important; */
}
.numberField .p-inputnumber-input{
  text-align: end;
  padding: 7px;
  width: 8.5rem;
  /* min-width: 100% !important; */
}
.numberField .p-inputnumber-input:enabled:hover{
  border-color: #0057db;
}
.numberField .p-inputnumber-input:focus{
  box-shadow: 0px 0px 4px 0rem #0057db !important;
}

/* table scrollbar */
/* Verticle Scrollbar */
.StatementOfDutiesPaidOrPayable .p-datatable-wrapper::-webkit-scrollbar {
  width: 0.4em; 
}
.StatementOfDutiesPaidOrPayable .p-datatable-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
.StatementOfDutiesPaidOrPayable .p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
  background: #aaa !important;
}

/* Horizontal scrollbar */
.StatementOfDutiesPaidOrPayable .p-datatable-wrapper::-webkit-scrollbar:horizontal {
  height: 0.4em; 
}
.StatementOfDutiesPaidOrPayable .p-datatable-wrapper::-webkit-scrollbar-thumb:horizontal {
  background-color: #ccc;
  border-radius: 10px;
}
.StatementOfDutiesPaidOrPayable .p-datatable-wrapper::-webkit-scrollbar-thumb:horizontal:hover {
  background: #aaa !important;
}

/* while scrollbar dropdown inde issue overlapping */
.StatementOfDutiesPaidOrPayable tbody tr{
  z-index: 0;
  position: relative;
} 
.StatementOfDutiesPaidOrPayable tbody tr td:nth-child(2){
  z-index: -1;
  position: relative;
}
.StatementOfDutiesPaidOrPayable tbody tr td:nth-child(3){
  z-index: -1;
  position: relative;
}
.StatementOfDutiesPaidOrPayable tbody tr td:nth-child(5){
  z-index: -1;
  position: relative;
}
.StatementOfDutiesPaidOrPayable tbody tr td:nth-child(6){
  z-index: -1;
  position: relative;
}



.indexColumn {
    text-align:center !important;
    font-size:0.875rem !important
}
.renmittancePeriodBoxA {
    /* max-width: 136px !important; */
    /* max-height: 37px !important; */
    min-width: 100% !important;
    margin: 8px 0 !important;
    padding: 7px !important;
    text-align: center !important;
  }
  
.StatementOfDutiesPaidOrPayable tbody tr:last-child td{
  /* height: 200px; */
  min-height: fit-content !important;
  font-size: 0.875rem !important;
}
.StatementOfDutiesPaidOrPayable tbody tr td{
  /* height: 200px; */
  /* min-height: fit-content !important;
  font-size: 0.875rem !important; */
  max-width: fit-content !important;
}
.StatementOfDutiesPaidOrPayable tbody {
  font-size: 0.875rem !important;
}

.StatementOfDutiesPaidOrPayable tbody td:last-child{
  /* height: 200px; */
  /* min-height: fit-content !important; */
  /* font-size: 0.875rem !important; */
  text-align: right !important;
}
.StatementOfDutiesPaidOrPayable tbody tr td:nth-child(n+3){
  /* height: 200px; */
  /* min-height: fit-content !important; */
  /* font-size: 0.875rem !important; */
  text-align: center !important;
}
.StatementOfDutiesPaidOrPayable tbody tr td:last-child{
  /* height: 200px; */
  /* min-height: fit-content !important; */
  /* font-size: 0.875rem !important; */
  text-align: right !important;
}
.StatementOfDutiesPaidOrPayable tbody tr:last-child td:nth-child(n+3){
  /* height: 200px; */
  /* min-height: fit-content !important; */
  /* font-size: 0.875rem !important; */
  text-align: right !important;
}