.slip-dropdown .p-dropdown-label{
    padding: 0 0 0 10px;
    font-size: 0.8rem;
}
.slip-dropdown .p-dropdown-trigger{
    padding: 0 5px;
    width: fit-content !important;
}
.slip-dropdown .p-dropdown-trigger .p-icon{
    /* padding: 0 5px; */
    margin-top: 2px !important;
    width: 11px !important;
}
.verticle-divider{
    margin: 0 8px 0 4px !important;
}
.verticle-divider .p-divider-content{
    background-color: #ebecf0 !important;
    padding: 0 !important;
}
.verticle-divider::before{
    border-left: 1px solid rgba(0, 87, 219, 0.5) !important;
}
.verticle-divider .p-divider .p-divider-vertical::before{
    border-left: 1px rgba(0, 87, 219, 0.5) !important;
}
.search-field{
    font-size: .875rem !important;
    padding: 0.65rem !important;
}
.p-dropdown-panel{
    font-size: .875rem !important;
}

.p-invalid {
    border-color: #d32f2f;
}
.p-error {
    color: #d32f2f;
    font-size: 12px;
}