
/* .rl3ReportDataTableStyle
  .p-datatable-table
  .p-datatable-tbody
  .p-highlight{
  background-color: #e9ecef !important;
  color: #000 !important;
} */

.rl3ReportDataTableStyle
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 8px !important
}
.rl3ReportDataTableStyle
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 8px !important
}
.rl3ReportDataTableStyle
  .p-datatable-table
  .p-datatable-tfoot
  td {
    padding: 8px !important
}
.rl3ReportDataTableStyle
  .p-datatable-table
  .p-datatable-thead
   {
    z-index: 0 !important;
}