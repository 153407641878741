
/* .primeDataGrid
  .p-datatable-table
  .p-datatable-tbody
  .p-highlight{
  background-color: #e9ecef !important;
  color: #000 !important;
} */

.primeDataGrid
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 6px !important
}
.primeDataGrid
  .p-datatable-table
  .p-datatable-tfoot
  td {
    padding: 6px !important
}
.primeDataGrid
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 6px !important
}
.primeDataGrid
  .p-datatable-table
  .p-datatable-thead
  .settingHeader .p-column-header-content {
    justify-content:center !important
}
.primeDataGrid
  .p-datatable-table
  .p-datatable-thead
  .selectionHeader .p-column-header-content {
    justify-content:center !important
}
.primeDataGrid
  .p-datatable-table
  .p-datatable-thead
  .indexColumn .p-column-header-content {
    justify-content:center !important;
}
.primeDataGrid
  .p-datatable-table
  .p-datatable-tbody
  .indexColumn {
    text-align:center !important
}
/* paginator Style */
.primeDataGrid
  .p-paginator {
    font-size: small !important;
}
.primeDataGrid
  .p-paginator
  .p-paginator-pages
  .p-paginator-page {
    /* font-size: small !important; */
    height: 40px;
    min-width: 40px;
}
.primeDataGrid
.p-dropdown {
  height: 40px !important;
}
.primeDataGrid
.p-dropdown 
.p-dropdown-label {
  padding: 7px !important;
}
.primeDataGrid
  .p-dropdown-trigger {
   width: auto !important;
   padding: 6px !important;
}
.primeDataGrid
  .p-dropdown-trigger
  .p-dropdown-trigger-icon  {
   width: 13px !important;
}

/* table header search bar */
.primeDataGrid
  .p-datatable-header
  .p-inputtext  {
    font-size: small !important;
}

body
  .p-tooltip{
    font-size: 10px !important;
}

/* datePicker */
.p-datepicker {
  z-index: 2001 !important;
}


/* comment bcz of scrool height in t4 report and others..... */
/* .primeDataGrid
  .p-datatable-table
  .p-datatable-thead
 {
    z-index: auto !important;
} */


/* comment bcz of scrool height in t4 report and others..... */
/* .primeDataGrid
  .p-datatable-table
  .p-datatable-thead {
    z-index: 0 !important;
} */