/* .ClientAdjustmentReportTableStyle
  .p-datatable-table
  .p-datatable-tbody
  .p-highlight{
  background-color: #e9ecef !important;
  color: #000 !important;
} */
.ClientAdjustmentReportTableStyle .p-datatable-table .p-datatable-thead {
  z-index: auto !important;
}
/* .ClientAdjustmentReportTableStyle .p-datatable-table .p-datatable-header .css-26l3qy-menu {
  z-index: auto !important;
  background-color: blue;
} */
.p-datatable-table .p-datatable-tfoot {
  z-index: auto !important;
}

.ClientAdjustmentReportTableStyle .p-datatable-table .p-datatable-tbody td {
  padding: 9px !important;
}
.ClientAdjustmentReportTableStyle .p-datatable-table .p-datatable-tfoot td {
  padding: 9px !important;
}
.ClientAdjustmentReportTableStyle .p-datatable-table .p-datatable-thead th {
  padding: 9px !important;
}

.ClientAdjustmentReportTableStyle
  .p-datatable-table
  .p-datatable-thead
  .indexColumn {
  padding-left: 18px !important;
}
