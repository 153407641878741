
.invoiceDataTable
  .p-datatable-table
  .p-datatable-tbody
  .p-highlight{
  background-color: #e9ecef !important;
  color: #000 !important;
}

.invoiceDataTable
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 6px !important
}
.invoiceDataTable
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 6px !important
}
.invoiceDataTable
  .p-datatable-table
  .p-datatable-thead
   {
    z-index: 0 !important;
}

/* for admin invoice */

.invoiceAdminDataTable
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 6px !important
}
.invoiceAdminDataTable
  .p-datatable-table
  .p-datatable-tbody
  td:last-child {
    text-align: center !important
}
.invoiceAdminDataTable
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 6px !important;
    white-space:normal !important
}
.invoiceAdminDataTable
  .p-datatable-table
  .p-datatable-thead
   {
    z-index: 0 !important;
}

.invoiceAdminDataTable
  .p-datatable-table
  .p-datatable-tfoot
  td {
    padding: 6px !important
}


/* datePicker */
.p-datepicker {
  z-index: 2001 !important;
}