/* greeen */
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(-n + 9) {
  background-color: #e3fcef;
}
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(-n + 9):hover {
  background-color: #e3fcef;
}
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(-n + 9)::selection {
  background-color: #e3fcef;
}

/* yellow */
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(n + 10):nth-child(-n + 13) {
  background-color: #fffae6;
}
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(n + 10):nth-child(-n + 13):hover {
  background-color: #fffae6;
}
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(n + 10):nth-child(-n + 13)::selection {
  background-color: #fffae6;
}

/* blue */
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(n + 14):nth-child(-n + 17) {
  background-color: #deebff;
}
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(n + 14):nth-child(-n + 17):hover {
  background-color: #deebff;
}
.mergeResourcesDataTable
  .p-datatable-thead
  .p-sortable-column:nth-child(n + 14):nth-child(-n + 17)::selection {
  background-color: #deebff;
}


/* sub Header */
.mergeResourcesDataTable
  .p-datatable-table
  .p-datatable-tbody
  .p-rowgroup-header{
  background-color: aliceblue;
}

.mergeResourcesDataTable
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 5px !important
}
.mergeResourcesDataTable
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 5px !important
}


.p-card .p-card-body
  .p-card-footer
   {
    padding: 3px 0 0 0 !important;
} 

.p-card .p-card-content{
  padding: 1px 0 0 0 !important;
}

.row-red {
  background-color: lightpink !important;  
  /* lightcoral */
}

.row-yellow {
  background-color: antiquewhite !important;
  /* Other styles */
}
.row-green {
  background-color: lightgreen !important;
  /* Other styles */
}
.mergeResourcesDataTable
  .p-datatable-table
  .p-datatable-thead {
    z-index: 0 !important;
}