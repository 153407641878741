.transition {
    /* width: 100px;
    height: 100px;
    background-color: blue; */
    transition: width 2.5s ease !important; /* Transition on the width property over 0.5 seconds with ease timing function */
  }
  
  .hidden {
    width: 0 !important; /* Set width to 0 when hidden */
  overflow: hidden !important; /* Hide overflow to ensure smooth transition */
  }