.transactionDetailTableStyle
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 9px !important
}
.transactionDetailTableStyle
.p-datatable-table
.p-datatable-thead
th {
  padding: 9px !important
} 
.transactionDetailTableStyle .p-datatable-table {
  display:table;
}
.transactionDetailTableStyle .p-datatable-table .p-datatable-frozen-tbody{
  display:table-footer-group;
  box-shadow: rgb(0 0 0 / 21%) 0px -3px 4px -2px;
  font-weight: bold;
}
.transactionDetailTableStyle .p-datatable-table .p-datatable-frozen-tbody
.p-selectable-row .p-frozen-column{
  color:#fff;
  user-select:none
}
.transactionDetailTableStyle .p-datatable-table .p-datatable-frozen-tbody
.p-selectable-row:hover .p-frozen-column{
color:#e9ecef
}
.transactionDetailTableStyle .p-datatable-table .p-datatable-frozen-tbody
.p-selectable-row .p-align-left:nth-last-child(2){
text-align: left !important;
}
.transactionDetailTableStyle .p-datatable-table .p-datatable-tbody
.p-selectable-row .p-align-left:nth-last-child(-n+2){
text-align: end;
}