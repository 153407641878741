.UpdateInvoiceDataTableStyle
  .p-datatable-table
  .p-datatable-tbody
  .p-highlight {
  background-color: #e9ecef !important;
  color: #000 !important;
}

.UpdateInvoiceDataTableStyle .p-datatable-table .p-datatable-tbody td {
  padding: 9px !important;
}
.UpdateInvoiceDataTableStyle .p-datatable-table .p-datatable-thead th {
  padding: 9px !important;
}

.UpdateInvoiceDataTableStyle
  #currency-us {
  padding: 9.3px 14px !important;
}
.UpdateInvoiceDataTableStyle
  {
  width: 100% !important;
  margin: 8px 0px 4px 0px !important;
}
