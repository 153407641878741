
.moveReourceDataTableStyle
  .p-datatable-wrapper {
    max-height: 500px !important;
    font-size: .875rem !important;
}
.moveReourceDataTableStyle
  .p-datatable-wrapper::-webkit-scrollbar {
    width: 0.5em !important;
}
.moveReourceDataTableStyle
  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background-color: #ccc !important;
    border-radius: 3px !important;
}
.moveReourceDataTableStyle
  .p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #aaa !important;
}
.moveReourceDataTableStyle
  .p-datatable-table
  .p-datatable-thead
  {
    position: sticky;
    top: 0;
    z-index: 1;
}
.moveReourceDataTableStyle
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 8px !important
}
.moveReourceDataTableStyle
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 8px !important;
    /* white-space: inherit; */
}
.moveReourceDataTableStyle
  .p-datatable-table
  .p-datatable-tfoot
  td {
    padding: 8px !important
}