
.CSVInformationUI
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 2px !important
}
.CSVInformationUI
  .p-datatable-table
  .p-datatable-tbody
  td:first-child {
    padding: 10px !important
}
.CSVInformationUI
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 5px !important;
    white-space:normal !important
}
.CSVInformationUI
  .p-datatable-table
  .p-datatable-thead
  th:first-child {
    padding: 10px !important;
}

.error-input {
  border: 2px solid red !important;
  border-radius: 6px !important; 
  width: -webkit-fill-available;
}
.input-dropdown-fields {
  width: -webkit-fill-available;
}

