.employeeReportDataTableStyle
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 6px !important
}
.employeeReportDataTableStyle
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 6px !important;
    white-space: inherit;
}
.employeeReportDataTableStyle
  .p-datatable-table
  .p-datatable-thead
   {
    z-index: 0 !important;
}