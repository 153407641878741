.myDataTable .p-datatable-table {
  display:table;
  font-size: 0.755rem;
  
}
.myDataTable .p-datatable-table .p-datatable-thead{
  /* order:1; */

  /* comment bcz of scroll height in  */
  /* z-index: 0 !important; */
}
.myDataTable .p-datatable-table .p-datatable-tbody{
  /* order:2; */
}
.myDataTable .p-datatable-table .p-datatable-frozen-tbody{
  /* order:3; */

  /* comment bcz of scroll height in  */
  /* z-index: 0 !important; */
  display:table-footer-group;
  box-shadow: rgb(0 0 0 / 21%) 0px -3px 4px -2px;
  font-weight: bold;
}
.myDataTable .p-datatable-table .p-datatable-tfoot{
  order:4;
}
.myDataTable .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row{
  cursor: auto;
  background-color: #FAFAFA;
}
.myDataTable tbody .p-selectable-row:hover{
  background-color: #fff !important;
}
.myDataTable .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row:nth-last-child(2){
  color:red;
}
.myDataTable .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row:nth-last-child(2)::selection{
  color:red;
}
.myDataTable .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row:nth-last-child(2):hover{
  color:red !important;
}
.myDataTable .p-datatable-thead .p-sortable-column:nth-child(-n+5){
  background-color:#e3fcef !important;
}
 .myDataTable .p-datatable-thead .p-sortable-column:nth-child(-n+5):hover{
  background-color:#e3fcef !important;
}
.myDataTable .p-datatable-thead .p-sortable-column:nth-child(n+6):nth-child(-n+9){
  background-color:#fffae6 !important;
}
.myDataTable .p-datatable-thead .p-sortable-column:nth-child(n+6):nth-child(-n+9):hover{
  background-color:#fffae6 !important;
}
.myDataTable .p-datatable-thead .p-sortable-column:nth-child(n+10):nth-child(-n+13){
  background-color:#ffebe6 !important;
} 
.myDataTable .p-datatable-thead .p-sortable-column:nth-child(n+10):nth-child(-n+13):hover{
  background-color:#ffebe6 !important;
} 
.myDataTable .p-datatable-thead .p-sortable-column:nth-child(n+14):nth-child(-n+17){
  background-color:#deebff !important;
} 
.myDataTable .p-datatable-thead .p-sortable-column:nth-child(n+14):nth-child(-n+17):hover{
  background-color:#deebff !important;
}  

.myDataTable
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 5px 3px !important
}

.myDataTable
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 4px 0px 4px 2px !important
}

.myDataTable .p-datatable-table .p-datatable-thead .align-header-group-column-text .p-column-header-content{
  justify-content: center;
}

/* .myDataTable .p-datatable-table .p-selectable-row .p-align-left:nth-child(-n+5){
  background-color:#e3fcef;
} */
.myDataTable .p-datatable-table .p-selectable-row .p-align-left:nth-child(n+6):nth-child(-n+9){
  background-color:#fffae6;
}
.myDataTable .p-datatable-table .p-selectable-row .p-align-left:nth-child(n+10):nth-child(-n+13){
  background-color:#ffebe6;
} 
.myDataTable .p-datatable-table .p-selectable-row .p-align-left:nth-child(n+14):nth-child(-n+17){
  background-color:#deebff;
} 
/* .myDataTable .p-datatable-frozen-tbody .p-selectable-row:nth-last-child(1) .p-align-left{
  background-color:#fff !important;
} */

/* index Column styling */
.myDataTable
  .p-datatable-table
  .p-datatable-tbody
  .indexColumn {
    padding: 10px !important
}
.myDataTable
  .p-datatable-table
  .p-datatable-thead
  .indexColumn {
    padding: 10px !important
}
.myDataTable
  .p-datatable-table
  .p-datatable-frozen-tbody
  .indexColumn {
    color: #fff !important;
    user-select: none !important;
}