
/* .generalClientsDataTableStyle
  .p-datatable-table
  .p-datatable-tbody
  .p-highlight{
  background-color: #e9ecef !important;
  color: #000 !important;
} */

.generalClientsDataTableStyle
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 9px !important
}
.generalClientsDataTableStyle
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 9px !important
}

.generalClientsDataTableStyle
  .p-datatable-table
  .p-datatable-thead
  .indexColumn {
    padding-left: 18px !important
}
.generalClientsDataTableStyle
  .p-datatable-table
  .p-datatable-thead
   {
    z-index: 0 !important;
}