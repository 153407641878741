
.JournalExportDataTable
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 6px !important
}
.JournalExportDataTable
  .p-datatable-table
  .p-datatable-tbody
  td:nth-last-child(3) {
    text-align: end !important
}
.JournalExportDataTable
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 6px !important;
    /* white-space:normal !important */
}

.JournalExportDataTable
  .p-datatable-table
  .p-datatable-thead
  .p-reorderable-column .p-column-header-content .p-column-title {
    width: 100% !important;
}
.JournalExportDataTable
  .p-datatable-header {
    text-align: end !important;
}
/* 
.JournalExportDataTable
  .p-datatable-table
  .p-datatable-tfoot
  td {
    padding: 6px !important
} */
.JournalExportDataTable
  .p-datatable-table
  .p-datatable-thead
   {
   z-index: 0 !important;
}