.MuiToolbar-root .menu-bar{
    border:0 !important;
    /* background:"#fff" !important; */
    /* padding:0 !important; */
    margin: 0 10px;
    border-radius: 0 !important;
    font-size: 0.875rem !important;
}
.MuiToolbar-root .menu-bar .MuiSvgIcon-root{
    font-size: 1.2rem !important;
    margin-right: 3px;
}
.MuiToolbar-root .menu-bar .p-menuitem-link{
    padding: 0.5rem !important;
}