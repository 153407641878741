/* .pierReviewDataTableAbove2023 .p-datatable-table {
  display:grid;
}
.pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-thead{
  order:1;
}
.pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-tbody{
  order:2;
}
.pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-frozen-tbody{
  order:3;
  box-shadow: rgb(0 0 0 / 21%) 0px -3px 4px -2px;
  font-weight: bold;
} */
/* .pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-tfoot{
  order:4;
} */
/* .pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row{
  cursor: auto;
  background-color: #FAFAFA;
} */
/* .pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row:nth-last-child(2){
  color:red;
}
.pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row:nth-last-child(2)::selection{
  color:red;
}
.pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-frozen-tbody .p-selectable-row:nth-last-child(2):hover{
  color:red !important;
} */
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(-n+8){
  background-color:#e3fcef;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(-n+8):hover{
  background-color:#e3fcef;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+9):nth-child(-n+14){
  background-color:#fffae6;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+9):nth-child(-n+14):hover{
  background-color:#fffae6;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+15):nth-child(-n+17){
  background-color:#ffebe6;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+15):nth-child(-n+17):hover{
  background-color:#ffebe6;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+18):nth-child(-n+20){
  background-color:#deebff;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+18):nth-child(-n+20):hover{
  background-color:#deebff;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+21):nth-child(-n+26){
  background-color:#bec4cc;
}
.pierReviewDataTableAbove2023 .p-datatable-thead .p-sortable-column:nth-child(n+21):nth-child(-n+26):hover{
  background-color:#bec4cc;
}


.pierReviewDataTableAbove2023 .p-datatable-table {
  font-size: 0.755rem;
}

.pierReviewDataTableAbove2023
  .p-datatable-table
  .p-datatable-tbody
  td {
    padding: 8px !important
}
.pierReviewDataTableAbove2023
  .p-datatable-table
  .p-datatable-thead
  th {
    padding: 8px !important;
    /* white-space: inherit; */
}
.pierReviewDataTableAbove2023
  .p-datatable-table
  .p-datatable-tfoot
  td {
    padding: 8px !important
}


.pierReviewDataTableAbove2023 .p-datatable-table .p-datatable-thead .p-resizable-column .p-column-header-content{
  justify-content: center;
}

.pierReviewDataTableAbove2023 .p-datatable-table tbody .p-align-left:nth-child(-n+8){
  background-color:#e3fcef;
}
.pierReviewDataTableAbove2023 .p-datatable-table tbody .p-align-left:nth-child(n+9):nth-child(-n+14){
  background-color:#fffae6;
}
.pierReviewDataTableAbove2023 .p-datatable-table tbody .p-align-left:nth-child(n+15):nth-child(-n+17){
  background-color:#ffebe6;
}
.pierReviewDataTableAbove2023 .p-datatable-table tbody .p-align-left:nth-child(n+18):nth-child(-n+20){
  background-color:#deebff;
}
.pierReviewDataTableAbove2023 .p-datatable-table tbody .p-align-left:nth-child(n+21):nth-child(-n+26){
  background-color:#bec4cc;
}
/* .pierReviewDataTableAbove2023 .p-datatable-table .p-align-left:nth-child(n+9):nth-child(-n+12){
  background-color:#ffebe6;
} 
.pierReviewDataTableAbove2023 .p-datatable-table .p-align-left:nth-child(n+13):nth-child(-n+16){
  background-color:#deebff;
}  */

.pierReviewDataTableAbove2023 .p-datatable-thead {
  z-index: 0 !important;
}