table .SubItemsDataTable thead{
    display: none;
}

.p-datatable-table
  .p-datatable-tbody
  td {
    padding: 6px !important
}
table thead th {
    padding: 6px !important
}
table .input-dropdown-fields .p-dropdown-label {
    padding: 5px 10px;
}
.p-dropdown-panel .p-dropdown-items-wrapper ul {
    padding: 5px 0;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
    padding: 0 5px;
}

.error-input {
    border: 2px solid red !important;
    border-radius: 6px !important; 
    width: -webkit-fill-available;
  }